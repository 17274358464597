<template>
  <div class="card">
    <div
      v-b-toggle="`collapsePlaceOfTalk`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">Event/Place</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapsePlaceOfTalk`"
      visible
    >
      <div class="card-content">
        <div class="card-body">
          <!-- <p>The pointer is positioned automatically. You can move it manually.</p> -->
          <div class="row">
            <div class="col-7">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                > {{ labels['event_place.title'] }} {{ activity.type == 5 ? '(Institution/Department/Laboratory/Library/Fieldwork site/etc)' : '' }}</label>
                <input
                  v-model="activity.event_name"
                  type="text"
                  class="form-control"
                >
              </div>
            </div>
            <div class="col-sm-5">
              <div class="mb-1 form-group required">
                <label
                  for=""
                  class="form-label"
                >{{ labels['event_place.country'] }}</label>
                <v-select
                  v-model="activity.country"
                  label="name"
                  :options="countries"
                  :reduce="e => e.name"
                />
              </div>
            </div>
            <!-- <div class="col-sm-2">
              <div
                class="mb-1 form-group"
                :class="{'required': activity.content && (!activity.content.virtual_activity)  && (activity.country && activity.country.toLowerCase() !== 'spain' && activity.country.toLowerCase() !== 'españa')}"
              >
                <label
                  for=""
                  class="form-label"
                >Postal code</label>
                <input
                  v-model="activity.postal_code"
                  type="text"
                  class="form-control"
                >
              </div>
            </div> -->
          </div>
          <div class="row">
            <div class="col-8">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['event_place.complete_postal_address'] }}</label>
                <textarea
                  v-model="activity.address"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-sm-8">
              <div class="mb-1">
                <label
                  for=""
                  class="form-label"
                >{{ labels['event_place.postal_address_google_maps'] }}</label>
                <div class="input-group">
                  <vue-google-autocomplete
                    id="map"
                    ref="autocompletegoogle"
                    v-model="activity.complete_address"
                    classname="form-control"
                    types=""
                    placeholder="Search for a location"
                    @placechanged="getAddressData"
                  />
                  <a
                    id="linkToWeb"
                    :href="`https://www.google.com/maps?${activity.cid || ''}`"
                    class="btn btn-outline-primary"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Go to link"
                    target="_blank"
                  ><i data-feather="external-link" /></a>
                </div>
              </div>
            </div>
          </div>
          <div class="row"> <!-- el mapa no se muestra hasta que existe un :focus sobre el input search direction -->
            <div class="col">
              <div
                v-if="showMap"
                id="placeMap"
                class="mt-2"
                style="width:100%;height:300px;"
              />
              <p class="mt-1">
                <a
                  class="text-dark"
                  @click="toggleMap"
                ><i data-feather="eye" /> {{ showMap ? 'Hide' : 'Show' }} map</a>
              </p>
              <!-- <p class="mt-1"><a href="#" class="text-dark"><i data-feather='eye-off'></i> Hide map</a></p> -->
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCollapse,
    VueGoogleAutocomplete,
    vSelect,
  },
  props: {
    activity: { type: Object, required: true },
  },
  data() {
    return {
      showMap: false,
    }
  },
  computed: {
    ...mapGetters({
      countries: 'citizenships/items',
      labels: 'sectionLabels/itemLabels',
    }),
    address() {
      return this.activity.address
    },
  },
  watch: {
    address() {
      if (this.activity.address && !this.activity.lat) {
        /* this.$refs.autocompletegoogle.geolocate() */
      } else {
        this.changeMap(this.activity)
      }
    },
  },
  async mounted() {
    this.$store.dispatch('citizenships/fetch')
    if (this.activity.lat && this.activity.lng) {
      this.changeMap(this.activity)
    }
  },
  methods: {
    getFullAddress() {
      if (this.activity.address && this.activity.country) {
        return `${this.activity.address}, ${this.activity.country}`
      }

      return ''
    },
    async getAddressData(data, placeResultData) {
      const countryCode = placeResultData.address_components.find(item => item.long_name === data.country).short_name

      this.activity.lat = data.latitude
      this.activity.lng = data.longitude
      this.activity.postal_code = data.postal_code
      const cid = placeResultData.url.split('?')[1]
      this.activity.cid = cid

      const resp = await this.$store.dispatch('citizenships/filter', { iso2: countryCode })

      if (resp.length > 0) {
        this.activity.country = resp[0].name
      }

      this.activity.address = placeResultData.formatted_address
      this.activity.complete_address = placeResultData.formatted_address

      this.$forceUpdate()
      if (this.showMap) {
        this.changeMap(this.activity)
      }
      this.$forceUpdate()
    },
    changeMap(data) {
      // The location
      const uluru = { lat: parseFloat(data.lat), lng: parseFloat(data.lng) }
      // The map, centered at Uluru
      if (document.getElementById('placeMap')) {
        const map = new google.maps.Map(
          document.getElementById('placeMap'),
          {
            zoom: 13,
            center: uluru,
          },
        )

        // The marker, positioned at Uluru
        const marker = new google.maps.Marker({
          position: uluru,
          map,
        })
      }

      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    toggleMap() {
      this.showMap = !this.showMap

      setTimeout(() => {
        if (this.showMap) {
          this.changeMap(this.activity)
        }
      }, 500)
    },
  },
}
</script>
