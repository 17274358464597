<template>
  <div
    v-if="activity.relatedActivities && activity.relatedActivities.length > 0"
    class="card"
  >
    <div
      v-b-toggle="`collapseRelatedActivities`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">Overlapping activities</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseRelatedActivities`"
      visible
    >
      <div class="card-content collapse show"> <!-- sólo se muestra "show" si hay texto en el textarea -->
        <div class="card-body">
          <div class="table-responsive-sm">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Name</th>
                  <th>Begin date - end date</th>
                  <th>Country</th>
                  <th>TSS</th>
                  <th>Travel info</th>
                  <th class="text-end">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="activ in activity.relatedActivities"
                  :key="activ.id"
                >
                  <td>{{ getTitle(activ) }}</td>
                  <td>{{ activ.name }}</td>
                  <td>{{ activ.dates }}</td>
                  <td>{{ activ.country }}</td>
                  <td>{{ activ.tss }}</td>
                  <td>{{ activ.travel_info }}</td>
                  <td class="text-end">
                    <a
                      :href="$router.resolve({name: `researcher.${getUrl(activ)}.edit`, params: { id: activ.id }, query: { actAs: $route.query.actAs }}).href"
                      target="_blank"
                      class="
                        btn btn-icon btn-icon
                        rounded-circle
                        btn-flat-dark
                        me-1
                      "
                    ><i data-feather="eye" /></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCollapse,
  },
  computed: {
    ...mapGetters({
      activity: 'activities/item',
    }),
  },
  methods: {
    getTitle(activity) {
      switch (activity.type) {
        case 1:
          return 'Conference'
        case 2:
          return 'PhD & Master Theses and TFG'
        case 3:
          return 'Course or seminar'
        case 4:
          return 'Managerial activity'
        case 5:
          return 'Stay of research'
        case 6:
          return 'Dissemination'
        default:
          return ''
      }
    },
    getUrl(activity) {
      switch (activity.type) {
        case 1:
          return 'conferences'
        case 2:
          return 'phds'
        case 3:
          return 'courses'
        case 4:
          return 'managerial'
        case 5:
          return 'stays-research'
        case 6:
          return 'disseminations'
        default:
          return ''
      }
    },
  },
}
</script>
