<template>
  <div class="card">
    <div
      v-b-toggle="`collapseMultimedia`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">External content <span class="badge bg-light-success">{{ item.multimedia.length }}</span></a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseMultimedia`"
    >
      <div class="card-content collapse show"> <!-- sólo se muestra "show" si hay texto en el textarea -->
        <div class="card-body">
          <p>{{ labels['description'] }}</p>
        </div>
        <div class="table-responsive-sm">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th>Title</th>
                <th>Description</th>
                <th>Type</th>
                <th>Link</th>
                <th>Created</th>
                <th class="text-end">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="multi, index in item.multimedia"
                :key="multi.id"
              >
                <td>{{ multi.title }}</td>
                <td><small v-html="multi.description" /></td>
                <td><span class="badge bg-light-secondary">{{ multi.type }}</span></td>
                <td>
                  <a
                    :href="multi.link.startsWith('http') ? multi.link : `//${multi.link}`"
                    target="_blank"
                  >
                    {{ multi.link }}
                  </a>
                </td>
                <td>{{ multi.created }}</td>
                <td class="text-end">
                  <a
                    :href="multi.link.startsWith('http') ? multi.link : `//${multi.link}`"
                    target="_blank"
                    class="btn btn-icon btn-icon rounded-circle btn-flat-dark me-1"
                  ><i data-feather="eye" /></a>
                  <a
                    class="btn btn-icon btn-icon rounded-circle btn-flat-danger"
                    @click="deleteMultimedia(index)"
                  ><i data-feather="trash-2" /></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="card-body">
          <a
            v-b-toggle="`collapseMultimediaAdd`"
            class="btn btn-sm btn-dark dis-ib"
          ><i data-feather="plus" /> Add multimedia</a>

          <b-collapse
            :id="`collapseMultimediaAdd`"
          >
            <div
              class="mt-3"
            >
              <div class="card shadow-none bg-transparent border-secondary">
                <div class="card-body">
                  <h3>Add link</h3>
                  <div class="row">
                    <div class="col">
                      <div class="mb-1">
                        <label
                          for="formFile"
                          class="form-label"
                        >{{ labels['add_link.link'] }}</label>
                        <input
                          v-model="multimedia.link"
                          class="form-control"
                          type="text"
                        >
                      </div>
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['add_link.name'] }}</label>
                        <input
                          v-model="multimedia.title"
                          type="text"
                          class="form-control"
                        >
                      </div>
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['add_link.description'] }}</label>
                        <quill-editor v-model="multimedia.description" />
                      </div>
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['add_link.type'] }}</label>
                        <select
                          v-model="multimedia.type"
                          class="form-select"
                        >
                          <option value="">
                            Select type of link
                          </option>
                          <option value="Web">
                            Web
                          </option>
                          <option value="Video">
                            Video
                          </option>
                          <option value="Audio">
                            Audio
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="mb-2">
                    <a
                      v-b-toggle="`collapseMultimediaAdd`"
                      class="btn btn-sm btn-outline-success me-1"
                      @click="addMultimedia"
                    >Add link</a>
                    <a
                      v-b-toggle="`collapseMultimediaAdd`"
                      class="btn btn-sm btn-flat-danger"
                    >Cancel</a>
                  </div>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue'
import Vue from 'vue'

export default {
  components: {
    BCollapse,
  },
  props: {
    item: { type: Object, required: true, default: () => {} },
  },
  data() {
    return {
      multimedia: {},
      labels: [],
    }
  },
  async mounted() {
    try {
      const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/showLabel/components.multimedia`)
      this.labels = data.data
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    deleteMultimedia(index) {
      this.item.multimedia.splice(index, 1)
    },
    addMultimedia() {
      this.item.multimedia.push(this.multimedia)
      this.multimedia = {}
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
    },
  },
}
</script>
